<template>
  <div class="vipPanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New Building') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Company')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.company"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Address')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.address"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Zip')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.zip"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Location')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.location"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('KG')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.kg"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('GST')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.gst"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Bank')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.bank"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Usage')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.usage"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Total space')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.space"
                            />
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('QM-IST')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.qmIst"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Rent P.A')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.rentPa"
                            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Qm potential')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.qmPotential"
                            />
            </b-form-group>
          </b-col>



          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Open credit')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.open"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Buy price')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.buyPrice"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Interest credit')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.interest"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Yearly cost')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.yearlyCost"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Credit end')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.creditEnd"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Lien')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.lien"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Appraisal Worth')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.appraisalWorth"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Sell Worth')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.sellWorthIntern"
                            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Book Worth')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.bookWorth"
                            />
            </b-form-group>
          </b-col>

          <!-- Branch -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Branch')" label-for="realEstate-branch">
              <v-select
                  multiple
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="building.buids"
                  :options="allBranches"
                  class="w-100"
                  label="name"
                  :reduce="(branch) => branch.id"
                  :clearable="false"

              />
            </b-form-group>
          </b-col>


        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'vipPanel',
  components: {},
  data() {
    return {
      loaded: false,
      error: null,
      buildingId: 0,
      building: {
        company: '',
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitBuilding', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitBuilding', this.listeners.submit)
  },
  methods: {
    ...mapActions('building', {
      _register: 'addBuilding',
    }),
    onLoad() {
      this.loaded = true
    }
    ,
    createBuilding() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
            if (!valid)
              return;
            $this._register(this.building)
          .then(
              (id) => {

                $this.buildingId = id
                $this.$emit('submitted', {
                  status: true,
                  server: id,
                  id

                })
              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
          .finally(() => {
            console.log('id: ', $this.buildingId)
            this.$router.push('/building/edit/' + $this.buildingId)

          })
      })
    },

    validate(field) {
      return this.$v.building[field] != null && this.$v.building[field].$error === true
    }
    ,
    submit() {
      return this.createBuilding()
    }

  },

}
</script>


