<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <!--
                <b-row>


                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Company") }}</label>
                    <b-form-input
                        v-model="filter.like.company"
                        class="d-inline-block mr-1"
                    />
                  </b-col>
                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Address") }}</label>
                    <b-form-input
                        v-model="filter.like.address"
                        class="d-inline-block mr-1"
                    />
                  </b-col>

                  &lt;!&ndash; Type&ndash;&gt;
                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Zip") }}</label>
                    <b-form-input
                        v-model="filter.like.zip"
                        class="d-inline-block mr-1"
                    />
                  </b-col>

                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Location") }}</label>
                    <b-form-input
                        v-model="filter.like.location"
                        class="d-inline-block mr-1"
                    />
                  </b-col>
                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Usage") }}</label>
                    <b-form-input
                        v-model="filter.like.usage"
                        class="d-inline-block mr-1"
                    />
                  </b-col>
                   <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>{{ msg("Total Space") }}</label>
                    <b-form-input
                        v-model="filter.like.space"
                        class="d-inline-block mr-1"
                    />
                  </b-col>


                </b-row>
        -->
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Onboarding Properties') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"

                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button disabled variant="primary" @click="onVipClubSelect(0)">
                  <span class="text-nowrap">{{ msg('New member') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="vipClub-table"
            striped
            hover
            responsive
            v-bind:items="vipClubs"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>

          <template #cell(affiliateId)="data">
            <div v-if="getAllUsers[data.value] != null">
              <b-link @click="onClientSelect(getAllUsers[data.value].id)">{{ getAllUsers[data.value].firstname }}
                {{ getAllUsers[data.value].lastname }}
              </b-link>
            </div>

          </template>
          <template #cell(clientId)="data">
            <div v-if="clients[data.value] != null">
              <b-link @click="onClientSelect(clients[data.value].id)">{{ clients[data.value].firstname }}
                {{ clients[data.value].lastname }}
              </b-link>
            </div>

          </template>
          <template #cell(creationDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>
          <template #cell(status)="data" class="p-0">
            <b-badge :variant="statusVariants[data.value]">{{ data.value }}</b-badge>
          </template>
          <template #cell(active)="data" class="p-0">
            <b-badge :variant="data.value?'success':'danger'">{{ data.value? msg('yes'):msg('no') }}</b-badge>
          </template>
          <template #cell(paid)="data" class="p-0">
            <b-badge :variant="data.value?'success':'danger'">{{ data.value? msg('yes'):msg('no') }}</b-badge>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.vipClub" size="xl" ref="vipClub-modal" id="vipClub-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <vip-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitVipClub">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import VipPanel from "@/views/realEstate/vipClub/vipPanel";

export default {
  name: 'Vips',
  components: {
    VipPanel
  },
  data() {
    return {
      file: null,
      loading: true,
      amount: 10,
      vipClubs: [],
      fields: [],
      clients: {},
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        lte: {},
        gte: {},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {vipClub: false},
      addressName: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
        this.uploadFile()
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),


    allvipClubs() {
      return this.vipClubs
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('vipClub', ['getAllVipClubs', 'copySuccess']),
    ...mapActions('clients', ['getClients']),


    resetFile() {
      this.file = null;
    },

    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [


        {
          key: 'affiliateId',
          label: $this.msg('User'),
          sortable: false
        },
        {
          key: 'clientId',
          label: $this.msg('client'),
          sortable: false
        },
        {
          key: 'eventTitle',
          label: $this.msg('Event Title'),
          sortable: true
        },
        {
          key: 'paid',
          label: $this.msg('Paid'),
          sortable: true
        },
        {
          key: 'active',
          label: $this.msg('Active'),
          sortable: true
        },
        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },
        {
          key: 'creationDate',
          label: $this.msg('creationDate'),
          sortable: true
        }
      ]
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.getAllVipClubs({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onVipClubsRetrieve, (error) => $this.error = error)


    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
    onVipClubsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.vipClubs = data["VipClubs"];
      this.loadClients();

      this.loading = false;
    },
    loadClients() {
      let $this = this;
      let clients = this.vipClubs.map(vip => vip.clientId)
      this.getClients({filter: {ids: clients}, amountPerPage: 9999}).then(({clients}) => {
        $this.clients = clients.toObject("id");
      })
    },
    onVipClubSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.vipClub = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(vipClub) {
      this.$router.push(`/vip/edit/${vipClub.id}`);
    },
    submitVipClub() {
      this.$root.$emit('crm::submitVipClub')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.vipClub = false;
    }
  }
}
</script>

<style>

</style>
